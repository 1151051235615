#chatbot-root .screen-bg {
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#chatbot-root .screen-box {
  width: calc(100% - 30px);
  max-width: 600px;
  /*height: 465px;*/
  height: auto;
  flex-direction: column;
  background: #1467c3;
  border-radius: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 15px;
  position: relative;
}

#chatbot-root .video-call-btn {
  border: none;
  background-color: #1467c3;
  color: #fff;
}

#chatbot-root .welcome-text {
  margin-top: 30px;
  margin-bottom: 25px;
}

#chatbot-root .screen-box h1 {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
}

#chatbot-root .welcome-text p {
  color: #fff;
  margin-bottom: 25px;
}

#chatbot-root .login-signup a:not(:last-child) {
  margin-right: 15px;
}

#chatbot-root .t-and-c {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}

#chatbot-root .t-and-c a {
  color: #fff;
  text-decoration: underline;
}

#chatbot-root .t-and-c a:hover {
  /* Add hover effect styles here */
}

#chatbot-root .screen-box .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

#chatbot-root .screen-box .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

#chatbot-root .screen-box .form-control::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

#chatbot-root .screen-box .form-control {
  border-color: #fff;
  background: transparent;
  color: #fff;
}

#chatbot-root .remember-me {
  position: relative;
  display: flex;
  align-items: center;
}

#chatbot-root .remember-me label {
  color: #fff !important;
  cursor: pointer;
}

#chatbot-root .remember-me .form-check-input {
  position: relative;
  margin-left: 0px;
  margin-top: 0px;
  margin-right: 5px;
}

#chatbot-root .remember-me .form-check-input:checked {
  background-color: #fff;
  outline: none;
  box-shadow: none;
}

#chatbot-root .forgot-pass {
  text-align: right;
}

#chatbot-root .forgot-pass a {
  color: #fff;
}

#chatbot-root .or-separator {
  position: relative;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

#chatbot-root .or-separator span {
  background: #1467c3;
  position: relative;
  z-index: 9;
  padding: 0px 12px;
  display: inline-block;
}

#chatbot-root .or-separator:before {
  content: "";
  width: 50%;
  background: #fff;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

#chatbot-root .or-separator:after {
  content: "";
  width: 50%;
  background: #fff;
  height: 1px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

#chatbot-root .social-login {
  display: flex;
  align-items: center;
  justify-content: center;
}

#chatbot-root .social-login a {
  font-weight: 600;
  color: #1e1e1e;
  display: flex;
  align-items: center;
}

#chatbot-root .social-login a:not(:last-child) {
  margin-right: 20px;
}

#chatbot-root .social-login a img {
  margin-right: 8px;
}

#chatbot-root .signup-screen h2 {
  color: #fff;
  margin-bottom: 10px;
  font-size: 20px;
}

#chatbot-root .signup-screen p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0px;
}

#chatbot-root .signup-now {
  color: rgb(255 255 255 / 70%);
}

#chatbot-root .signup-now a {
  color: #fff;
  font-weight: 600;
}

#chatbot-root .signup-now a:hvoer {
  /* Add hover effect styles here */
}

#chatbot-root .forgot-password p,
#chatbot-root .reset-password p {
  color: #fff;
  margin-bottom: 25px;
}

#chatbot-root .password-visibility {
  position: relative;
}

#chatbot-root .password-visibility input {
  padding-right: 55px;
}

#chatbot-root .see-password {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 100%;
  display: flex;
  color: rgb(255 255 255 / 70%);
  font-size: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#chatbot-root .not-matched {
  color: #fdc101;
  text-align: left;
  padding-left: 20px;
}

#chatbot-root .enter-opt p {
  color: #fff;
  margin-bottom: 25px;
}

#chatbot-root .opt-phone {
  text-underline-offset: 3px;
  font-weight: 600;
  text-decoration: underline;
  display: block;
  font-size: 16px;
}

#chatbot-root .opt-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

#chatbot-root .opt-col input {
  border-radius: 10px;
  width: 35px;
  height: 35px;
  padding: 5px;
  text-align: center;
}

#chatbot-root .opt-col input:not(:last-child) {
  margin-right: 15px;
}

#chatbot-root .resend-code {
  color: #fff;
}

#chatbot-root .resend-code a {
  text-decoration: underline;
  font-weight: 600;
  color: #fff;
}

#chatbot-root .layout-wrap {
  overflow: hidden;
  display: flex;
  background: #edf6ff;
  height: 100vh;
}

#chatbot-root .side-nav {
  width: 102px;
  background: #1467c3;
  text-align: center;
  padding: 25px 15px;
  height: 100vh;
}

#chatbot-root .admin-logo {
  margin-bottom: 75px;
  display: block;
}

#chatbot-root .side-nav ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

#chatbot-root .side-nav ul li:not(:last-child) {
  margin-bottom: 20px;
}

#chatbot-root .side-nav ul li a {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background: transparent;
  border-radius: 50%;
}

#chatbot-root .side-nav ul li a:hover,
#chatbot-root .side-nav ul li a.active {
  background: #fff;
}

#chatbot-root .side-nav ul li a:hover svg path,
#chatbot-root .side-nav ul li a.active svg path {
  fill: #1467c3;
}

#chatbot-root .right-area {
  overflow: hidden;
  height: calc(100vh - 20px);
  width: 100%;
}

#chatbot-root .top-bar {
  background: #fff;
  width: 100%;
  padding: 20px;
  height: 105px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#chatbot-root .top-left ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
}

#chatbot-root .top-left ul li {
  font-size: 12px;
  font-weight: 500;
  color: #1e1e1e;
  position: relative;
}

#chatbot-root .top-left ul li:not(:last-child) {
  margin-right: 7px;
  padding-right: 7px;
}

#chatbot-root .top-left ul li:not(:last-child):after {
  content: "/";
  position: absolute;
  right: -3px;
}

#chatbot-root .top-left ul li a {
  text-decoration: none;
  color: #1467c3;
}

#chatbot-root .top-left h1 {
  font-size: 28px;
  font-weight: 700;
  color: #1e1e1e;
  margin-bottom: 5px;
}

#chatbot-root .top-right > ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
}

#chatbot-root .top-right > ul > li:not(:last-child) {
  margin-right: 20px;
}

#chatbot-root .top-right > ul > li > a {
  font-size: 22px;
  color: #1e1e1e;
}

#chatbot-root .top-right ul li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

#chatbot-root .top-right ul li:not(:last-child) a:hover svg path {
  fill: #1467c3;
}

#chatbot-root .change-status-html {
  position: relative;
}

#chatbot-root .change-status-html ul {
  display: none;
  position: absolute;
  width: 140px;
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  text-align: left;
  padding: 15px;
  z-index: 9;
}

#chatbot-root .change-status-html ul li:not(:last-child) {
  border-bottom: 1px solid rgba(198, 198, 198, 0.5);
  padding-bottom: 5px;
  margin-bottom: 5px;
}

#chatbot-root .change-status-html ul li {
  color: #1e1e1e;
  font-size: 13px;
  cursor: pointer;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

#chatbot-root .current-status-html {
  background: #ffffff;
  border: 1px solid #1467c3;
  border-radius: 25px;
  padding: 7px 40px 7px 25px;
  font-size: 14px;
  color: #1e1e1e;
  cursor: pointer;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

#chatbot-root .status-color-html {
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 100%;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  background: #1ba428;
}

#chatbot-root .current-status-html .fa {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

#chatbot-root .change-status {
  /* Add styles for change-status here */
}

#chatbot-root .current-status {
  position: relative;
}

#chatbot-root .status-color {
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 100%;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}

#chatbot-root .status-color.active {
  background: #1ba428;
}

#chatbot-root .status-color.away {
  background: #ffba00;
}

#chatbot-root .status-color.dnd {
  background: #f44336;
}

#chatbot-root .status-color.invisible {
  background: #8a8d91;
}

#chatbot-root .status-color.custom {
  background: #1ba428;
}

#chatbot-root .current-status select {
  outline: none;
  background: #ffffff;
  border: 1px solid #1467c3;
  border-radius: 25px;
  padding: 7px 30px 7px 25px;
  font-size: 14px;
  color: #1e1e1e;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
}

#chatbot-root .current-status .fa {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

#chatbot-root .list-of-status ul li span {
  cursor: pointer;
  color: #000000;
}

#chatbot-root .list-of-status ul li:not(:last-child) {
  margin-bottom: 3px;
}

#chatbot-root .profile-status {
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 100%;
  right: 0px;
  z-index: 9;
  bottom: 2px;
}

#chatbot-root .profile-status.active {
  background: #1ba428;
}

#chatbot-root .profile-image {
  position: relative;
}

#chatbot-root .profile-image img {
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 50%;
  object-fit: cover;
}

#chatbot-root .profile-name {
  margin-left: 12px;
  font-size: 16px;
  font-weight: 600;
  color: #1e1e1e;
}

#chatbot-root .top-right ul li a:hover .username {
  color: #1467c3;
}

#chatbot-root .main-area {
  padding: 20px;
}

#chatbot-root .beside-chat {
  background: #fff;
  border-radius: 20px;
  height: 100%;
}

#chatbot-root .beside-header {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#chatbot-root .beside-header h2 {
  font-size: 22px;
  font-weight: 600;
  color: #1e1e1e;
  margin-bottom: 0px;
}

#chatbot-root .users-search form {
  position: relative;
}

#chatbot-root .users-search button {
  width: 41px;
  height: 41px;
  background: #1467c3;
  border: 0px;
  outline: none;
  box-shadow: none;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  top: 0;
}

#chatbot-root .users-search button:hover {
  /* Add hover effect styles here */
}

#chatbot-root .users-wrap .beside-header-right {
  /* Add styles here */
}

#chatbot-root .beside-header-right > ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
}

#chatbot-root .beside-header-right > ul > li:not(:last-child) {
  margin-right: 25px;
}

#chatbot-root li.refine a {
  font-size: 25px;
  color: #1e1e1e;
}

#chatbot-root li.export a {
  font-size: 25px;
  color: #1e1e1e;
}

#chatbot-root li.export a {
  position: relative;
}

#chatbot-root li.export a {
  width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  text-decoration: none;
  border-radius: 50%;
}

#chatbot-root li.export:hover a {
  background: #1467c3;
  color: white;
}

#chatbot-root li.refresh a {
  font-size: 25px;
  color: #1e1e1e;
}

#chatbot-root .beside-header ul li.add-user a {
  width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1467c3;
  text-decoration: none;
  border-radius: 50%;
}

#chatbot-root .users-listing {
  width: 100%;
  height: calc(100vh - 249px);
  overflow-y: auto;
  padding-right: 10px;
}

#chatbot-root .users-listing > ul {
  /* Add styles here */
}

#chatbot-root .users-listing > ul > li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #edf6ff;
  padding: 15px;
  border-radius: 15px;
}

#chatbot-root .users-listing > ul > li:not(:last-child) {
  margin-bottom: 15px;
}

#chatbot-root .users-listing > ul > li > .user-info {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  width: 41.66666667%;
}

#chatbot-root .user-image {
  position: relative;
  margin-right: 15px;
}

#chatbot-root .user-image img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 50%;
}

#chatbot-root .user-action {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  width: 16.66666667%;
  justify-content: end;
}

#chatbot-root .user-action button {
  border: 0;
  padding: 0;
  font-size: 18px;
  color: #1e1e1e;
  background: transparent;
}

#chatbot-root .name-email h3 {
  font-size: 16px;
  color: #1e1e1e;
  font-weight: 600;
  margin-bottom: 5px;
}

#chatbot-root .name-email a {
  color: #1e1e1e;
  text-decoration: none;
}

#chatbot-root .name-email a:hover {
  color: #1467c3;
}

#chatbot-root .user-depart {
  text-align: center;
  flex: 0 0 auto;
  width: 41.66666667%;
}
#chatbot-root .user-department {
  /* text-align: center; */
  flex: 0 0 auto;
  width: 35%;
}

#chatbot-root .member-type {
  font-size: 16px;
  display: block;
  font-weight: 500;
}

#chatbot-root .user-action ul li a:hover.user-edit {
}

#chatbot-root .user-action ul li a:hover.user-trash {
  /*	color: red;*/
}

#chatbot-root .online-status:after {
  background: #49e26c;
}

#chatbot-root .busy-status:after {
  background: #ef5757;
}

#chatbot-root .away-status:after {
  background: #ffba00;
}

#chatbot-root .invisible-status:after {
  background: #a2a4a7;
}

#chatbot-root .user-image:after {
  content: "";
  width: 13px;
  height: 13px;
  position: absolute;
  right: 3px;
  top: -3px;
  border-radius: 50%;
}

#chatbot-root .users-list {
  position: relative;
  padding: 0px 15px 15px 15px;
}

#chatbot-root .list-wrap {
  position: relative;
  display: flex;
  padding-top: 15px;
  border-top: 1px solid #c6c6c6;
}

#chatbot-root .sort-a-to-z {
  order: 1;
  text-align: center;
  margin-left: 10px;
}

#chatbot-root .sort-a-to-z ul {
  height: calc(100vh - 249px);
}

#chatbot-root .sort-a-to-z ul li:not(:last-child) {
  margin-bottom: 2px;
}

#chatbot-root .sort-a-to-z ul li a {
  text-decoration: none;
  color: #c6c6c6;
  width: 20px;
  display: block;
  text-transform: uppercase;
}

#chatbot-root .sort-a-to-z ul li a:hover,
.sort-a-to-z ul li a.active {
  color: #1e1e1e;
}

#chatbot-root .chat-wrap {
  background: #fff;
  border-radius: 20px;
}

#chatbot-root .chats-list {
  position: relative;
  padding: 0px 15px 15px 15px;
}

#chatbot-root .chats-wrap {
  position: relative;
  display: flex;
  padding-top: 15px;
  border-top: 1px solid #c6c6c6;
}

#chatbot-root .generate-call {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: #edf6ff;
  padding: 15px;
  border-radius: 15px;
}

#chatbot-root .call-link {
}

#chatbot-root .call-link h3 {
  font-size: 16px;
  color: #1e1e1e;
  font-weight: 600;
  margin-bottom: 5px;
}

#chatbot-root .call-link span {
}

#chatbot-root .link-send a {
  font-size: 20px;
  color: #1e1e1e;
}

#chatbot-root .link-send a:not(:last-child) {
  margin-right: 15px;
}

#chatbot-root .link-send a:hover {
  color: #1467c3;
}

#chatbot-root .chats-listing {
  width: 100%;
  height: calc(100vh - 249px);
  /*overflow-y: auto;*/
  padding-right: 10px;
}

#chatbot-root .chats-listing ul {
  margin-top: 15px;
}

#chatbot-root .chats-listing ul > li {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

#chatbot-root .chats-listing ul > li > div {
  background: #edf6ff;
  padding: 15px;
  margin-left: 5px;
  border-radius: 15px;
  position: relative;
}

#chatbot-root .chats-listing ul > li:before {
  content: "";
  background: transparent;
  width: 15px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  border-radius: 15px 0px 0px 15px;
}

#chatbot-root .chats-listing ul > li.user-arrived:before {
  background: #b5b5b5;
}

#chatbot-root .chats-listing ul > li.nobody-responded:before {
  background: #e74646;
}

#chatbot-root .chats-listing ul > li.someone-messaged:before {
  background: #f1e92f;
}

#chatbot-root .chats-listing ul > li.someone-responded:before {
  background: #1ba428;
}

#chatbot-root .chats-listing ul li:hover > div {
  background: #e8f2fb;
}

#chatbot-root .chats-listing ul li:not(:last-child) {
  margin-bottom: 15px;
}

#chatbot-root .chats-listing ul li .chater-data {
  display: flex;
  align-items: center;
}

#chatbot-root .chater-img {
  position: relative;
  margin-right: 15px;
}

#chatbot-root .chater-img img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 50%;
}

#chatbot-root .chater-info h3 {
  font-size: 16px;
  color: #1e1e1e;
  font-weight: 600;
  margin-bottom: 5px;
}

#chatbot-root .chater-info {
  line-height: 20px;
}

#chatbot-root .chater-info span + span {
  font-size: 11px;
}

#chatbot-root .chat-new-msg {
  width: 20px;
  height: 20px;
  background: #1467c3;
  border-radius: 50%;
  color: #fff;
  font-weight: 600;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
}

#chatbot-root .add-department {
  cursor: pointer;
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  color: #c6c6c6;
}

#chatbot-root .add-department:hover {
  color: #1e1e1e;
}

#chatbot-root .add-new-department {
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 16px;
  color: #1e1e1e;
}

#chatbot-root .add-new-department a {
  color: #1467c3;
  font-size: 12px;
  margin-left: 10px;
  font-weight: 500;
}

#chatbot-root li.refine {
  position: relative;
}

#chatbot-root li.refine a {
  width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  text-decoration: none;
  border-radius: 50%;
}

#chatbot-root .filter-option {
  display: none;
  position: absolute;
  right: 0;
  z-index: 9;
  background: #fff;
  width: 250px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

#chatbot-root .filter-option h4 {
  font-weight: 600;
  font-size: 13px;
  border-bottom: 1px solid rgba(198, 198, 198, 0.5);
  margin-bottom: 7px;
  padding-bottom: 7px;
}

#chatbot-root .filter-option ul {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

#chatbot-root .filter-option ul li {
  width: 45%;
}

#chatbot-root .filter-option ul li .form-check {
  display: flex;
  align-items: center;
}

#chatbot-root .filter-option label {
  font-size: 12px;
  cursor: pointer;
  margin-left: 5px;
}

#chatbot-root li.refine:hover .filter-option {
  display: block;
}

#chatbot-root li.refine:hover a {
  background: #1467c3;
  color: #fff;
}

#chatbot-root .individual-chat {
  background: #fff;
  border-radius: 20px;
}

#chatbot-root .individual-header {
  padding: 15px;
}

#chatbot-root .individual-header ul {
  display: flex;
  align-items: center;
}

#chatbot-root .individual-call {
  display: flex;
  align-items: center;
  margin-left: auto;
}

#chatbot-root .individual-data {
  display: flex;
  align-items: center;
}

#chatbot-root .individual-data h3 {
  font-size: 15px;
  color: #1e1e1e;
  font-weight: 600;
  margin-bottom: 0px;
}

#chatbot-root .individual-data h3 span {
  font-size: 12px;
  font-weight: 400;
  display: block;
  margin-top: 5px;
}

#chatbot-root .individual-dp {
  position: relative;
  margin-right: 10px;
}

#chatbot-root .individual-dp img {
  width: 41px;
  height: 41px;
  object-fit: contain;
  border-radius: 50%;
}

#chatbot-root .back-to-chat {
  margin-right: 15px;
}

#chatbot-root .back-to-chat a {
  width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1467c3;
  text-decoration: none;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}

#chatbot-root .individual-call button {
  border: 0;
  padding: 0;
  font-size: 20px;
  color: #1e1e1e;
  background: transparent;
}

#chatbot-root .chat-window {
  position: relative;
  padding: 0px 15px 15px 15px;
}

#chatbot-root .chat-window-wrap {
  position: relative;
}

#chatbot-root .message-content {
  position: relative;
}

#chatbot-root .chat-msg {
  width: 100%;
  overflow-y: scroll;
  margin-top: -5px;
  height: calc(100vh - 300px);
  /*    overflow-y: auto;*/
  margin-bottom: 15px;
  padding-right: 10px;
}
#chatbot-root .input-margin {
  margin-bottom: 0px !important;
}
#chatbot-root .chat-date {
  text-align: center;
}

#chatbot-root .chat-date span {
  font-size: 11px;
  background: #edf6ff;
  border-radius: 20px;
  display: inline-block;
  padding: 3px 15px;
}

#chatbot-root .user-msg {
  position: relative;
  text-align: right;
  width: calc(100% - 10%);
  float: right;
}

#chatbot-root .chat-text {
  font-size: 14px;
  background: #1467c3;
  border-radius: 30px;
  font-weight: 400;
  color: #fff;
  display: inline-block;
  padding: 6px 20px;
}


#chatbot-root .chat-time {
  font-size: 10px;
  color: #c6c6c6;
  display: block;
  margin-top: 1px;
}

#chatbot-root .chat-time img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  border-radius: 50%;
  margin-left: 3px;
  margin-top: -10px;
}

#chatbot-root .admin-msg {
  position: relative;
  text-align: left;
  width: calc(100% - 10%);
  float: left;
}

#chatbot-root .user-msg .chat-text {
  background: #edf6ff;
  color: #1e1e1e;
}

#chatbot-root .admin-msg .chat-time img {
  margin-right: 3px;
  margin-top: -10px;
}

#chatbot-root .chat-msg p:not(:last-child) {
  margin-bottom: 10px;
}

#chatbot-root .chat-msg p:last-child {
  margin-bottom: 0px;
}

#chatbot-root .send-msg {
  position: relative;
}

#chatbot-root .send-msg .form-control {
}

#chatbot-root .more-opt.active {
  background: #1467c3;
  color: #fff;
}

#chatbot-root .more-opt.active .fa:before {
  content: "\f107";
}

#chatbot-root .mini-menu {
  border: none;
  border-radius: 20px;
  -webkit-box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.15);
  position: absolute;
  bottom: 25px;
  right: 40px;
  z-index: 99;
  width: 180px;
  overflow: hidden;
  background-color: #fff;
}

#chatbot-root .mini-menu ul {
  margin: 2px;
  padding: 7px 0px;
}

#chatbot-root .mini-menu ul li {
}

#chatbot-root .mini-menu ul li a {
  display: block;
  padding: 7px 15px;
  color: #1e1e1e;
  text-decoration: none;
  font-size: 14px;
  position: relative;
  text-align: center;
}

#chatbot-root .mini-menu ul li a:hover {
  color: #1467c3;
}

#chatbot-root .mini-menu ul li a:before {
}

#chatbot-root .mini-menu ul li:not(:last-child) a:after {
  content: "";
  background: rgba(198, 198, 198, 0.5);
  height: 1px;
  width: 80%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

#chatbot-root .phone-caller {
  background: #1e1e1e;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 9;
  right: 0px;
  cursor: grab;
  top: 50%;
  transform: translateY(-55%);
  min-width: 202px;
  max-width: 202px;
  width: 100%;
}

#chatbot-root .phone-caller-img {
  position: relative;
  margin-right: 15px;
}

#chatbot-root .phone-caller-img img {
  width: 55px;
  height: 55px;
  object-fit: contain;
  border-radius: 50%;
}

#chatbot-root .phone-caller-name h3 {
  color: #fff;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}

#chatbot-root .phone-call-action {
  display: flex;
  align-items: center;
}

#chatbot-root .phone-call-action button {
  border: 0;
  padding: 0;
  font-size: 12px;
  color: #fff;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#chatbot-root .phone-call-action button + button {
  margin-left: 10px;
}

#chatbot-root .call-mic,
#chatbot-root .call-video {
  background: #1467c3;
}

#chatbot-root .call-cut {
  background: #e64646;
}

#chatbot-root .video-caller {
  border-radius: 20px;
  position: absolute;
  z-index: 9;
  overflow: hidden;
  right: 0px;
  cursor: grab;
  top: 50%;
  transform: translateY(-55%);
  min-width: 300px;
  max-width: 300px;
  width: 100%;
}

#chatbot-root .video-caller video {
  width: 100%;
  height: 160px;
  object-fit: cover;
  display: block;
}

#chatbot-root .video-call-action {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1e1e1e;
  padding: 8px 0px;
}

#chatbot-root .video-call-action button {
  border: 0;
  padding: 0;
  font-size: 12px;
  color: #fff;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1467c3;
}

#chatbot-root .video-call-action button + button {
  margin-left: 10px;
}

#chatbot-root .video-call-action button.action-cut {
  background: #e64646;
}

#chatbot-root .edit-user-modal select {
  color: #1e1e1e;
}

#chatbot-root .status-toggle {
  margin-bottom: 0px;
  text-align: left;
  padding: 0px !important;
  /* display: flex;
      align-items: center; */
}

#chatbot-root .status-toggle label {
  margin-right: 15px;
  pointer-events: none;
}

#chatbot-root .status-toggle .form-check-input:checked {
  background-color: #49e26c;
  border-color: #49e26c;
}

#chatbot-root .status-toggle .form-check-input {
  width: 40px;
  height: 20px;
  border-radius: 110px;
  margin: 0;
  padding: 0;
}

/* Onboarding */
#chatbot-root .onboard-step {
  max-width: 600px;
  width: 100%;
  margin: auto;
}

#chatbot-root .onboard-step ul {
  margin-bottom: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
#chatbot-root .onboard-step ul li {
  flex: 0 0 auto;
  /* width: 25%; */
  position: relative;
}

#chatbot-root .onboard-step ul li span {
  width: 54px;
  height: 54px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #1e1e1e;
  background: #c6c6c6;
  border-radius: 50%;
  margin: 0px auto;
  z-index: 999;
  position: relative;
}

#chatbot-root .onboard-step ul li:last-child:after {
  content: none !important;
}

#chatbot-root .onboard-step ul li.active span {
  background: #1467c3;
  color: white;
}


#chatbot-root .onboard-step ul li.active:after {
  background: #1467c3;
  content: "";
  width: 50%;
  height: 4px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 99;
  transform: translateY(-50%);
}

#chatbot-root .onboard-step ul li.done > span {
  display: none;
}

#chatbot-root .onboard-step ul li.done > span + span {
  display: flex !important;
}

#chatbot-root .onboard-step ul li.active:before {
  background: #1467c3;
}

#chatbot-root .onboard-step ul li:first-child:before {
  content: none;
}

#chatbot-root .onboard-step ul li:before {
  content: "";
  width: 100%;
  height: 4px;
  background: #c6c6c6;
  position: absolute;
  left: -50%;
  top: 50%;
  z-index: 9;
  transform: translateY(-50%);
}

#chatbot-root .onboard-box {
  width: 100%;
  max-width: 900px;
  height: auto;
  text-align: center;
  /*flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;*/
  padding: 50px 15px;
  position: relative;
}

#chatbot-root .onboard-slide {
  background: #ffffff;
  border-radius: 20px;
  padding: 25px;
}

#chatbot-root .onboard-content {
  max-width: 750px;
  width: 100%;
  margin: auto;
}

#chatbot-root .onboard-content h2 {
  color: #1e1e1e;
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 20px;
  border-bottom: 1px solid #c6c6c6;
  margin-bottom: 20px;
}

#chatbot-root .onboard-video {
  position: relative;
}

#chatbot-root .onboard-video video {
  width: 100%;
  height: 350px;
  object-fit: cover;
  display: block;
  border-radius: 20px;
}

#chatbot-root .onboard-nav {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: end;
}

#chatbot-root .skip-link {
  color: #1e1e1e;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
}

#chatbot-root .next-btn {
  margin-left: 20px;
}

#chatbot-root .onboard-play {
  width: 65px;
  height: 65px;
  padding: 0;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

#chatbot-root .onboard-integrate img {
  max-width: 100%;
  margin-bottom: 15px;
}

#chatbot-root .onboard-welcome label {
  text-align: left;
  display: block;
  font-size: 16px;
  color: #1e1e1e;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

#chatbot-root .fade-in {
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

#chatbot-root .fade-in.show {
  opacity: 1;
}

#chatbot-root body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #000;
}

#chatbot-root .container {
  animation-name: example;
  animation-duration: 0.3s;
  width: 500px;
  margin: 0 auto;
  position: fixed;
  padding-right: 0;
  padding-left: 0;
  right: 25px;
  bottom: 70px;
  border-radius: 15px;
  background-color: white;
  border: 1px solid #000;
}

#chatbot-root .footer-svg {
  height: 50px;
  cursor: pointer;
  width: 50px;
  background-color: #F70000;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

@keyframes example {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#chatbot-root .header {
  background-color: #1467c3;
  border-radius: 15px 15px 0px 0px;
  color: white;
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#chatbot-root .header > div:first-child {
  display: flex;
}

#chatbot-root .footer {
  background-color: #eeeeee;
  border-radius: 0px 0px 15px 15px;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#chatbot-root ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}

#chatbot-root ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

#chatbot-root .logo {
  font-size: 18px;
  margin-left: 14px;
}

#chatbot-root .logo-b {
  margin-top: 2px;
  font-size: 11px;
}

#chatbot-root .menu-icon {
  font-size: 24px;
  display: flex;
}

#chatbot-root .minimize-icon {
  font-size: 24px;
  margin-top: -5.5px;
  margin-right: 9px;
}

#chatbot-root .content {
  padding: 20px 3px 15px 15px;
}

#chatbot-root .modal-backdrop {
  /* Add your backdrop styles here */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
}

#chatbot-root h1 {
  font-size: 20px;
  margin-bottom: 10px;
}

#chatbot-root p {
  margin-bottom: 20px;
}

#chatbot-root .button {
  display: block;
  width: calc(100% - 20px);
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
  background-color: white;
  color: #1467c3;
  text-align: center;
  text-decoration: none;
  border-radius: 20px;
  font-size: 15px;
}

#chatbot-root .string-cans-icon {
  height: 50px;
  cursor: pointer;
  width: 50px;
  background-color: white;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

#chatbot-root .chat-support {
  margin-top: -20px;
  padding-right: 12px;
  font-size: 14px;
}

#chatbot-root input[type="text"],
#chatbot-root input[type="email"] {
  padding: 6px 20px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-bottom: 15px;
}

#chatbot-root .chat-button {
  background-color: #1467c3;
  color: white;
  border: none;
}

#chatbot-root .powered-by {
  text-align: center;
  color: #000;
  margin: auto;
  font-size: 16px;
}

#chatbot-root .input-details {
  margin-bottom: 20px !important;
}

#chatbot-root .content-ind {
  background-color: #edf6ff;
  padding: 10px 10px;
  margin: 10px;
  border-radius: 10px;
}

#chatbot-root body {
  font-family: "Poppins";
}

#chatbot-root .send-msg-btn {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1467c3;
  border-radius: 50%;
  border: 0;
  padding: 0;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 6px;
  margin: 0px;
}

#chatbot-root .more-opt {
  border: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  color: #1e1e1e;
  border-radius: 30px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c6c6c6;
  position: absolute;
  right: 38px;
  top: 18.8px;
  transform: translateY(-50%);
}

#chatbot-root .small-img {
  margin-top: 5px; /* Adjust the spacing as needed */
  max-width: 200px; /* Adjust the maximum width as needed */
  height: auto; /* Maintain aspect ratio */
}

/* Onboarding */
/* Container for the entire upload area */
#chatbot-root .upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 218px;
  height: 88px;
  margin: 20px 0;
  padding: 20px;
  border-radius: 12px;
  background-color: #f3f4f6;
  cursor: pointer;
}

/* Hover effect for the upload container */
#chatbot-root .upload-container:hover {
  border-color: #1467c3;
}

/* Hidden file input */
#chatbot-root .file-input {
  display: none;
}

/* Label to cover the entire container */
#chatbot-root .upload-label {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* The box inside the upload container */
#chatbot-root .upload-box {
  text-align: center;
  color: #4a4a4a;
  font-family: 'Arial', sans-serif;
}

/* Icon for the upload box */
#chatbot-root .upload-icon {
  font-size: 18px;
  margin-bottom: -16px;
  display: block;
  color: #1467c3;
}

/* Text for the upload box */
#chatbot-root .upload-text {
  font-size: 14px;
  font-family: Poppins;
  font-weight: 700;
  color: #1467c3;
  display: block;
  margin-top: 19px;
}

#chatbot-root .form-control {
  display: block;
  width: 50%;
  margin: auto;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0.375rem;
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

/* Hover effect for the text */
#chatbot-root .upload-container:hover .upload-text {
  color: #1467c3;
}
